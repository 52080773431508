import React, {useEffect, useState, useContext, useReducer} from "react";
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import {Context as ScreenContext} from '../../context/ScreenContext'
import { Spinner } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

const MagicContents = (props) => {
  const {show_id, id} =  props.match.params;
  const {setAiContentPageMenu} = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(true)
  const [magicContentGroups, setMagicContentGroups] = useState([]);

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${show_id}/audioposts/${id}/magic_contents.json`)
    .then((res) => {
      screenContext.finishFetching();
      const {
        show,
        id,
        name,
        transcript_id,
        magic_content_processing,
        magic_content_groups
      } = res.data;

      setTitle(name);
      setLoading(magic_content_processing)
      setMagicContentGroups(magic_content_groups)

      setAiContentPageMenu({
        title: "AI Contents",
        currentPageId: "episodes",
        currentTabId: "ai-contents",
        subtitle: `${show.podcast_title} > ${name}`,
        showName: show.podcast_title,
        showId: show.id,
        episodeId: id,
        transcriptId: transcript_id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website
      })
    })
    .catch((err) => {
      screenContext.finishFetching();
    })
  }, [])

  if(screenContext.state.loading || loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div className="container-fluid">
      {magicContentGroups.map((group, id) => {
        return (
          <div className="row" key={id}>
            <div className="col-12">
              <Card>
                <CardHeader>
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-header-title">{group.request_name}</h4>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>

                  {
                    ["keywords", "introduction"].indexOf(group.request_key) > -1 ? <p>{group.results[0].map((content) => content.result ).join(", ")}</p> : <div>
                      <ol>
                        {group.results[0].map((content, id) => <li key={`${group.request_key}-${id}`}>{content.result}</li>) }
                      </ol>
                    </div>
                  }
                </CardBody>
              </Card>
              {/* <ContentCard
                data={contentPrompt}
                handleReRun={handleReRun}
              /> */}
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default MagicContents